import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import {
  SIDE_PADDING,
  BREAKPOINT,
  Spacer,
  Text1,
  Text3Light,
  Text4,
  TextStat,
  TextStatLabel,
  StatContainer
} from './shared'

export default function IntroPage({data}) {
  return (
    <IntroContainer id="intro">
      <BackgroundContainer>
        <BackgroundInnerContainer>
          <Img fluid={data.intro.background.fluid} loading={'eager'}/>
        </BackgroundInnerContainer>
      </BackgroundContainer>
      <ContentContainer>
        <div style={{height: 92}}/>
        <TitleContainer>
          <Text1 style={{color: 'white'}}>
            {data.intro.title}
          </Text1>
          <div style={{height: 8}}/>
          <Text3Light>{data.intro.subtitle}</Text3Light>
        </TitleContainer>
        <ImageContainer>
          <Img fluid={data.intro.explodedSensor.fluid} loading={'eager'}/>
        </ImageContainer>
        <LowerContentContainer>
          <DescriptionContainer>
            {data.intro.description}
          </DescriptionContainer>
          <StatsContainer>
            <Stat number={data.intro.stat1Number} label={data.intro.stat1Label}/>
            <Stat number={data.intro.stat2Number} label={data.intro.stat2Label}/>
            <Stat number={data.intro.stat3Number} label={data.intro.stat3Label}/>
          </StatsContainer>
        </LowerContentContainer>
        <Spacer height={100} mobileHeight={0}/>
      </ContentContainer>
    </IntroContainer>
  )
}

const IntroContainer = styled.div`
  padding: 0 ${SIDE_PADDING};
  position: relative;
`

const BackgroundContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden
`

const BackgroundInnerContainer = styled.div`
  height: 100%;
  width: 100%;
  min-width: 725px;
  
  .gatsby-image-wrapper {
    height: 100%;
  }
  
@media (max-width: ${BREAKPOINT}) {
  min-width: 825px;
}
`

const ContentContainer = styled.div`
position: relative;
`

const TitleContainer = styled.div`
max-width: 420px;
`

const ImageContainer = styled.div`
width: 67%;
margin: 0 auto;

@media (max-width: ${BREAKPOINT}) {
  width: 90%
}
`

const LowerContentContainer = styled.div`
display: flex;
justify-content: space-between;

@media (max-width: ${BREAKPOINT}) {
  flex-wrap: wrap;
}
`

const DescriptionContainer = styled(Text4)`
max-width: 413px;
margin-right: 24px;
`

const StatsContainer = styled.div`
display: flex;
justify-content: space-between;
flex-direction: row-reverse;
flex-wrap: wrap;
max-width: 680px;

@media (max-width: ${BREAKPOINT}) {
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  margin-top: 30px;
}
`

function Stat({number, label}) {
  return (
    <StatContainer>
      <Number>{number}</Number>
      <Spacer height={10} mobileHeight={0}/>
      <Label>{label}</Label>
    </StatContainer>
  )
}

const Number = styled(TextStat)`
color: white;

@media (max-width: ${BREAKPOINT}) {
  color: black;
}
`

const Label = styled(TextStatLabel)`
color: #828282;
`
