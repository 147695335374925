import React, {useState} from 'react'
import styled from 'styled-components'
import Logo from '../assets/logo.svg'
import {SIDE_PADDING, BREAKPOINT, Spacer, InternalLink, ExternalLink} from './shared'
import Img from 'gatsby-image/withIEPolyfill'

export default function FooterPage({data}) {
  return (
    <FooterContainer id="footer">
      <BackgroundContainer>
        <BackgroundInnerContainer>
          <Img fluid={data.footer.background.fluid} loading={'eager'} objectFit={'fill'}/>
        </BackgroundInnerContainer>
      </BackgroundContainer>
      <InnerContainer>
        <Spacer height={55}/>
        <Top>
          <Logo/>
          <ConnectContainer>
            <MailchimpForm/>
            <Spacer height={55}/>
            <SocialContainer>
              {data.allDatoCmsSocialProfile.edges.map(({node: profile}) => (
                <ExternalLink
                  key={profile.profileType}
                  href={profile.url}
                >
                  <Img fixed={profile.icon.fixed} backgroundColor={'transparent'}/>
                </ExternalLink>
              ))}
            </SocialContainer>
          </ConnectContainer>
        </Top>
        <Spacer height={92} mobileHeight={0}/>
        <Middle>
          <FooterContent header={data.footer.menu}>
            {data.allDatoCmsMenuItem.edges.map(({node: item}) => (
              <div key={item.label}>
                <div><InternalLink href={item.link}>{item.label}</InternalLink></div>
              </div>
            ))}
          </FooterContent>
          <FooterContent header={data.footer.contact}>
            <div>
              <InternalLink href={`tel:${data.footer.phoneNumber}`}>{data.footer.phoneNumber}</InternalLink>
            </div>
            <div>
              <InternalLink href={`mailto:${data.footer.emailAddress}`}>{data.footer.emailAddress}</InternalLink>
            </div>
          </FooterContent>
          {data.allDatoCmsOfficeAddress.edges.map(({node: address}) => (
            <Address key={address.city} city={address.city} line1={address.line1} line2={address.line2}
                     line3={address.line3} url={address.url}/>
          ))}
        </Middle>
        <Spacer height={187} mobileHeight={32}/>
        <Bottom>
          <Copyright>{data.footer.copyright}</Copyright>
          <LegalLinks>
            <LegalLink><ExternalLink href="/terms">{data.footer.terms}</ExternalLink></LegalLink>
            <LegalLink><ExternalLink href="/privacy">{data.footer.privacy}</ExternalLink></LegalLink>
          </LegalLinks>
        </Bottom>
        <Spacer height={55} mobileHeight={16}/>
      </InnerContainer>
    </FooterContainer>
  )
}

const FooterContainer = styled.div`
  position: relative;
  padding: 0 ${SIDE_PADDING};
  
  @media (max-width: ${BREAKPOINT}) {
    background-color: #F4F4F4;
  }
`

const FooterText = styled.div`
  font-family: 'TTNormsPro-Medium', sans-serif;
  font-size: 16px;
  line-height: 19px;
  
  @media (max-width: ${BREAKPOINT}) {
    font-size: 14px;
  }
`

const BackgroundContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
`

const BackgroundInnerContainer = styled.div`
  height: 100%;
  width: 100%;
  
  @media (max-width: ${BREAKPOINT}) {
    min-width: 700px;
    height: 700px;
  }
  .gatsby-image-wrapper {
    height: 100%;
  }
`


const InnerContainer = styled.div`
  position: relative;
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  
  svg {
    height: 52px;
    width: 250px;
  }
  svg path {
    fill: black;
  }
  @media (max-width: ${BREAKPOINT}) {
    flex-direction: column;
    
    svg {
      height: 40px;
      width: 192px;
    }
  }
`

const ConnectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const SubscribeText = styled(FooterText)`
  font-family: 'TTNormsPro-ExtraBold', sans-serif;
  color: #4F4F4F;
`

const PrivacyCommitmentText = styled(FooterText)`
  color: #4F4F4F;
  font-size: 14px;
  line-height: 20px;
`

function MailchimpForm() {
  const [email, setEmail] = useState('')
  return (
    <MailChimpFormContainer id="subscribe">
      <link href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css" rel="stylesheet" type="text/css"/>
      <Spacer height={0} mobileHeight={40}/>
      <SubscribeText>Join our newsletter</SubscribeText>
      <div id="mc_embed_signup">
        <form
          action="https://carbic.us19.list-manage.com/subscribe/post?u=33bcf8aa2aa4d1aad0ef418bf&amp;id=b4fddd5904"
          method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate"
          target="_blank" novalidate>
          <div id="mc_embed_signup_scroll">
            <input type="email" value={email} onChange={e => setEmail(e.target.value)} name="EMAIL" className="email"
                   id="mce-EMAIL" placeholder="email address"
                   required/>
            <div style={{position: 'absolute', left: -5000}} aria-hidden="true">
              <input type="text" name="b_33bcf8aa2aa4d1aad0ef418bf_b4fddd5904" tabindex="-1" value=""/>
            </div>
            <div className="clear">
              <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button"/>
            </div>
          </div>
        </form>
      </div>
      <PrivacyCommitmentText>We’re committed to your privacy. Carbic uses the information you provide to us to contact you about
        our relevant content, products, and services. You may unsubscribe from these communications at any
        time.</PrivacyCommitmentText>
    </MailChimpFormContainer>
  )
}

const MailChimpFormContainer = styled.div`
  max-width: 448px;
  
  #mc_embed_signup {
    clear:left; 
    font:14px Helvetica,Arial,sans-serif; 
    width:100%;
  }
`


const SocialContainer = styled.div`
  width: 120px;
  display: flex;
  justify-content: space-between;
  
  @media (max-width: ${BREAKPOINT}) {
    margin-top: 48px;
    align-self: center;
  }
`


const Middle = styled.div`
  margin: 0 -32px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-family: 'TTNormsPro-Medium', sans-serif;
  font-size: 16px;
  line-height: 19px;
  color: #4F4F4F;
`

const Label = styled.div`
  font-family: 'TTNormsPro-ExtraBold', sans-serif;
`

const FooterContentContainer = styled.div`
  width: 160px;
  line-height: 42px; 
  margin: 36px 32px 0;
`

function FooterContent({header, children}) {
  return (
    <FooterContentContainer>
      <Label>{header}</Label>
      {children}
    </FooterContentContainer>
  )
}


function Address({city, line1, line2, line3, url}) {
  return (
    <FooterContent header={city}>
      <ExternalLink href={url}>
        <div>{line1}</div>
        <div>{line2}</div>
        <div>{line3}</div>
      </ExternalLink>
    </FooterContent>
  )
}

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  
  @media (max-width: ${BREAKPOINT}) {
    flex-direction: column-reverse;
    align-items: center;
    height: 124px;
  }
`

const Copyright = styled(FooterText)`
  color: rgba(0, 0, 0, 0.5);
  @media (max-width: ${BREAKPOINT}) {
    color: #F4F4F4;
  }
`

const LegalLinks = styled.div`
  display: flex;
  justify-content: space-between;
  width: 240px;
  
  @media (max-width: ${BREAKPOINT}) {
    width: 180px;
  }
`

const LegalLink = styled(FooterText)`
   color: rgba(255, 255, 255, 0.5);
  @media (max-width: ${BREAKPOINT}) {
    color: #999999;
  }
`



