import React from 'react'
import '../styles/fonts.css'
import styled, {createGlobalStyle} from 'styled-components'
import browserLang from 'browser-lang'
import {Location, Redirect} from '@reach/router'
import HomePage from '../components/Home'
import Intro from '../components/Intro'
import HowItWorks from '../components/HowItWorks'
import Applications from '../components/Applications'
import Demo from '../components/Demo'
import Careers from '../components/Careers'
import About from '../components/About'
import Footer from '../components/Footer'
import {Spacer} from '../components/shared'
import {graphql} from 'gatsby'
import {HelmetDatoCms} from 'gatsby-source-datocms'

export default function IndexPage({data}) {
  return (
    <Location>
      {(location => (
        <IndexInner data={data} location={location}/>
      ))}
    </Location>
  )
}

function IndexInner({data, location}) {
  if (typeof window !== `undefined`) {
    const language = browserLang({
      languages: ['en', 'es'],
      fallback: 'en',
    })

    if (language === 'es' && location.location.pathname === '/') {
      return <Redirect to={'/es'} noThrow/>
    }
  }

  return (
    <IndexContainer>
      <HelmetDatoCms
        title={'Carbic'}
        seo={data.datoCmsSeoMetaTags}
        favicon={data.site.faviconMetaTags}
      >
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <meta name="keywords" content={data.sitewide.seoKeywords}/>
        <title>Carbic</title>
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Carbic",
            "url": "https://carbic.com",
            "logo": "${data.sitewide.logo.fixed.src}"
          }
        `}</script>
      </HelmetDatoCms>
      <GlobalStyle/>
      <HomePage data={data}/>
      <Intro data={data}/>
      <HowItWorks data={data}/>
      <Applications data={data}/>
      <Demo data={data}/>
      <Spacer height={100} mobileHeight={30}/>
      <Careers data={data}/>
      <Spacer height={120} mobileHeight={30}/>
      <About data={data}/>
      <Footer data={data}/>
      <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js"></script>
    </IndexContainer>
  )
}

const IndexContainer = styled.div`
          font-family: 'Gibson-Regular', sans-serif;
          `
const GlobalStyle = createGlobalStyle`
          html {
          box-sizing: border-box;
        }
          *, *:before, *:after {
          box-sizing: inherit;
        }
          body {
          margin: 0;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
          `

export const data = graphql`
  query IndexQuery($locale: String!) {
    site: datoCmsSite(locale: {eq: $locale}) {
      globalSeo {
        siteName
      }
      faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    sitewide: datoCmsSitewide {
      seoKeywords
      logo {
        fixed(width: 600, imgixParams: {fm: "png", auto: "compress"}) {
          ...GatsbyDatoCmsFixed
        }
      }
    }
    home: datoCmsHome(locale: {eq: $locale}) {
      title
      subtitle
      ctaButton
      contactSales
    }
    datoCmsSeoMetaTags {
      ...GatsbyDatoCmsSeoMetaTags
    }
    allDatoCmsMenuItem(filter: {locale: {eq: $locale}}, sort: {fields: [position], order: ASC}) {
      edges {
        node {
          label
          link
        }
      }
    }
    intro: datoCmsIntro(locale: {eq: $locale}) {
      title
      subtitle
      description
      background {
        fluid(imgixParams: {fm: "png", auto: "compress"}) {
          ...GatsbyDatoCmsFluid
        }
      }
      explodedSensor {
        fluid(imgixParams: {fm: "png", auto: "compress"}) {
          ...GatsbyDatoCmsFluid
        }
      }
      stat1Number
      stat1Label
      stat2Number
      stat2Label
      stat3Number
      stat3Label
    }
    about: datoCmsAbout(locale: {eq: $locale}) {
      stat1Number
      stat1Label
      stat2Number
      stat2Label
      stat3Number
      stat3Label
      testimonialContent
      testimonialName
      testimonialTitle
      testimonialCompany
      testimonialPhoto {
        fluid(maxWidth: 600, imgixParams: {fm: "jpg", auto: "compress"}) {
          ...GatsbyDatoCmsFluid
        }
      }
      ctaPhoto {
        fluid(maxHeight: 760, imgixParams: {fm: "jpg", auto: "compress"}) {
          ...GatsbyDatoCmsFluid
        }
      }
      webinarPhoto {
        fixed(width: 6000, imgixParams: {fm: "jpg"}) {
          ...GatsbyDatoCmsFixed
        }
      }
      webinarUrl
      showWebinar
      aboutTitle
      aboutContent
      managementTeam
    }
    allDatoCmsTeamMember(filter: {locale: {eq: $locale}}, sort: {fields: [position], order: ASC}) {
      edges {
        node {
            title
            name
            photo {
              fluid(maxWidth: 600, imgixParams: {fm: "jpg", auto: "compress"}) {
                ...GatsbyDatoCmsFluid
              }
            }
        }
      }
    }
    how: datoCmsHow(locale: {eq: $locale}) {
      title
      subtitle
      step1Title
      step1Content
      step2Title
      step2Content
      step3Title
      step3Content
      step4Title
      step4Content
    }
    applications: datoCmsApplication(locale: {eq: $locale}) {
      title
      subtitle
      overviewTitle
      overviewContentNode {
        childMarkdownRemark {
          html
        }
      }
      productionOptimizationTitle
      productionOptimizationContentNode {
        childMarkdownRemark {
          html
        }
      }
      waterServicesTitle
      waterServicesContentNode {
        childMarkdownRemark {
          html
        }
      }
      midstreamTitle
      midstreamContentNode {
        childMarkdownRemark {
          html
        }
      }
      otherSensorsTitle
      otherSensorsContentNode {
        childMarkdownRemark {
          html
        }
      }
      dataSheetsTitle
    }
    allDatoCmsDataSheet(filter: {locale: {eq: $locale}}, sort: {fields: [position], order: ASC}) {
      edges {
        node {
          title
          pdf {
            url
          }
        }
      }
    }
    demo: datoCmsDemo(locale: {eq: $locale}) {
      title
      subtitle
      explanation
      screenshot {
        fluid(maxWidth: 1440, imgixParams: {fm: "jpg", auto: "compress"}) {
          ...GatsbyDatoCmsFluid
        }
      }
      digitalWellsLink
      launch
    }
    careers: datoCmsCareer(locale: {eq: $locale}) {
      title
      subtitle
      description
      cta
      url
      leftPhoto {
        fluid(maxWidth: 600, imgixParams: {fm: "jpg", auto: "compress"}) {
          ...GatsbyDatoCmsFluid
        }
      }
      rightPhoto {
        fluid(maxWidth: 600, imgixParams: {fm: "jpg", auto: "compress"}) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
    footer: datoCmsFooter(locale: {eq: $locale}) {
      contact
      phoneNumber
      emailAddress
      copyright
      terms
      privacy
      background {
        fluid(imgixParams: {fm: "png", auto: "compress"}) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
    allDatoCmsOfficeAddress(filter: {locale: {eq: $locale}}, sort: {fields: [position], order: ASC}) {
      edges {
        node {
          city
          line1
          line2
          line3
          url
        }
      }
    }
    allDatoCmsSocialProfile(filter: {locale: {eq: $locale}}, sort: {fields: [position], order: ASC}) {
      edges {
        node {
          profileType
          url
          icon {
            fixed(width: 24, imgixParams: {fm: "png", auto: "compress"}) {
              ...GatsbyDatoCmsFixed
            }
          }
        }
      }
    }
  }
`
