import React from 'react'
import styled from 'styled-components'
import {slide as ReactBurgerMenu} from 'react-burger-menu'
import {
  Button,
  InternalLink,
  Schedule,
} from '../components/shared'

const MENU_BREAKPOINT = '1100px'

export default function Header({menuItems, contactSales}) {
  return (
    <div>
      <HideAbove breakpoint={MENU_BREAKPOINT}><HamburgerMenu menuItems={menuItems}/></HideAbove>
      <HideBelow breakpoint={MENU_BREAKPOINT}><DesktopMenu menuItems={menuItems} contactSales={contactSales}/></HideBelow>
    </div>
  )
}

const HideBelow = styled.div`
  display: block;
  @media (max-width: ${props => props.breakpoint}) {
     display: none;
  }
`


const HideAbove = styled.div`
  display: none;
  @media (max-width: ${props => props.breakpoint}) {
     display: block;
  }
`

function HamburgerMenu({menuItems}) {
  var styles = {
    bmBurgerButton: {
      position: 'fixed',
      width: '16px',
      height: '14px',
      right: '34px',
      top: '34px'
    },
    bmBurgerBars: {
      background: 'white'
    },
    bmBurgerBarsHover: {
      background: '#a90000'
    },
    bmCrossButton: {
      height: '24px',
      width: '24px'
    },
    bmCross: {
      background: '#bdc3c7'
    },
    bmMenuWrap: {
      position: 'fixed',
      height: '100%',
      top: 0
    },
    bmMenu: {
      background: '#555555',
      padding: '2.5em 1.5em 0',
      fontSize: '1.15em'
    },
    bmMorphShape: {
      fill: '#373a47'
    },
    bmItem: {
      display: 'inline-block'
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)'
    }
  }

  return (
    <ReactBurgerMenu styles={styles} right>
      {menuItems.map(item => <InternalLink href={item.link} key={item.link}>
        <HamburgerMenuItem>{item.label}</HamburgerMenuItem>
      </InternalLink>)}
    </ReactBurgerMenu>
  )
}

const HamburgerMenuItem = styled.div`
  line-height: 24px;
  color: white;
  padding: 0.8em;
`


function DesktopMenu({menuItems, contactSales}) {
  return (
    <DesktopMenuContainer>
      <InternalLink href={menuItems[0].link}><MenuItem
        key={menuItems[0].link} selected={true}>{menuItems[0].label}</MenuItem>
      </InternalLink>
      {menuItems.slice(1).map(item => <InternalLink href={item.link} key={item.link}>
        <MenuItem>{item.label}</MenuItem>
      </InternalLink>)}
      <ContactSales text={contactSales}/>
    </DesktopMenuContainer>
  )
}

const DesktopMenuContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 106px 0 0;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
`

const MenuItem = styled.div`
  font-family: 'TTNormsPro-Medium', sans-serif;
  font-size: 16px;
  line-height: 19px;
  color: rgba(255, 255, 255, ${props => (props.selected ? '1' : '0.5')});
  border-bottom: ${props => (props.selected ? '2px solid #FFFFFF' : 'none')};
  margin-right: 2.5vw;
  cursor: pointer;
`

function ContactSales({text}) {
  return (
    <Schedule uniqueID="contact-sales">
      <ContactSalesButton>{text}</ContactSalesButton>
    </Schedule>
  )
}

const ContactSalesButton = styled(Button)`
  border: 2px solid #ffffff;
  color: #ffffff;
`

