import React from 'react'
import styled from 'styled-components'
import {SIDE_PADDING, BREAKPOINT, Spacer, Text1, Text2, Text5} from './shared'
import useHover from '../utilities/useHover'
import {VideoPlayer} from './VideoPlayer'

export default function ApplicationsPage({data}) {
  const applications = data.applications
  const dataSheets = data.allDatoCmsDataSheet.edges.map(edge => edge.node)
  return (
    <Container id="applications">
      <Spacer height={74} mobileHeight={54}/>
      <TopContainer>
        {applications.title}
      </TopContainer>
      <Spacer height={64} mobileHeight={24}/>
      <ApplicationsContainer>
        <Application title={applications.overviewTitle} content={applications.overviewContentNode}
                     videoName={'007_HighlightedSensorsAndFlow'}/>
        <Application title={applications.productionOptimizationTitle}
                     content={applications.productionOptimizationContentNode} videoName={'008_Wellheads&Separators'}/>
        <Application title={applications.waterServicesTitle} content={applications.waterServicesContentNode}
                     videoName={'009_FracPond'}/>
        <Application title={applications.midstreamTitle} content={applications.midstreamContentNode}
                     videoName={'012_FullOGNoMovement'}/>
        <Application title={applications.otherSensorsTitle} content={applications.otherSensorsContentNode}
                     videoName={'011_GridofSensors'}/>
        <ApplicationContainer>
          <Background isHovered={false}/>
          <InnerContainer>
            <ApplicationTitle isHovered={false}>{applications.dataSheetsTitle}</ApplicationTitle>
            <Text5>
              <ul>
                {
                  dataSheets.map(dataSheet => (
                    <li key={dataSheet.title}>
                      <a href={dataSheet.pdf.url} download target="_blank" rel="noopener noreferrer">
                        {dataSheet.title}
                      </a>
                    </li>
                  ))
                }
              </ul>
            </Text5>
          </InnerContainer>
        </ApplicationContainer>
      </ApplicationsContainer>
    </Container>
  )
}

const Container = styled.div`
  padding: 0 ${SIDE_PADDING};
  
  @media (max-width: ${BREAKPOINT}) {
    padding: 0 4.3vw;
  }
`
const TopContainer = styled(Text1)`
  display: flex;
  justify-content: center;
`


const ApplicationsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

function Application({title, content, videoName}) {
  const [ref, isHovered] = useHover()

  return (
    <ApplicationContainer ref={ref} isHovered={isHovered}>
      <VideoContainer>
        <VideoPlayer name={videoName} allowPlay={isHovered} loop preplay/>
      </VideoContainer>
      <Background isHovered={isHovered}/>
      <InnerContainer>
        <ApplicationTitle isHovered={isHovered}>{title}</ApplicationTitle>
        <Content dangerouslySetInnerHTML={{__html: content.childMarkdownRemark.html}} isHovered={isHovered}/>
      </InnerContainer>
    </ApplicationContainer>
  )
}

const ApplicationContainer = styled.div`
  height: 40.5vw;
  width: 40.5vw;
  min-height: 510px;
  min-width: 510px;
  max-height: 700px;
  max-width: 700px;
  padding: 8.33vw 3.47vw 4.17vw;
  margin-right: 0.2vw;
  margin-bottom: 0.2vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  
  @media (max-width: ${BREAKPOINT}) {
    height: 91.4vw;
    width: 91.4vw;
    min-height: inherit;
    min-width: inherit;
    max-height: 500px;
    max-width: 500px
  }
`

const VideoContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  padding: 1px; // Safari Fix
`

const Background = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => props.isHovered ? 'rgba(0, 0, 0, 0.35)' : '#F4F4F4'};
`

const InnerContainer = styled.div`
  position: relative;
`

const ApplicationTitle = styled(Text2)`
  color: ${props => props.isHovered ? 'white' : 'black'};
`

const Content = styled(Text5)`
  color: ${props => props.isHovered ? 'transparent' : '#333333'};
`



