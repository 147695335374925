import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import {CTAButton, Spacer, Text1, Text3Dark, Text5, ExternalLink, SIDE_PADDING, BREAKPOINT} from './shared'

export default function CareersPage({data}) {
  return (
    <CareersContainer id="careers">
      <ImagesContainer>
        <ImageContainer>
          <Img fluid={data.careers.leftPhoto.fluid}/>
        </ImageContainer>
        <ImageContainer>
          <Img fluid={data.careers.rightPhoto.fluid}/>
          <ContentContainer>
            <ContentInnerContainer>
              <Spacer height={210} mobileHeight={48}/>
              <Text3Dark>{data.careers.title}</Text3Dark>
              <Text1>{data.careers.subtitle}</Text1>
              <Spacer height={32} mobileHeight={10}/>
              <DescriptionContainer><Text5>{data.careers.description}</Text5></DescriptionContainer>
              <Spacer height={30} mobileHeight={10}/>
              <ExternalLink href={data.careers.url}>
                <CTAButton>{data.careers.cta}</CTAButton>
              </ExternalLink>
            </ContentInnerContainer>
          </ContentContainer>
        </ImageContainer>
      </ImagesContainer>
    </CareersContainer>
  )
}

const CareersContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  padding: 0 ${SIDE_PADDING};
  
  @media (max-width: ${BREAKPOINT}) {
    padding: 0;
  }
`

const ImagesContainer = styled.div`
  width: 100%;
  display: flex;
  
  @media (max-width: ${BREAKPOINT}) {
    flex-wrap: wrap;
  }
`

const ImageContainer = styled.div`
  width: 100%;
  position: relative;
  
  @media (max-width: ${BREAKPOINT}) {
    height: 100vw;
    overflow: hidden;
  }
`

const ContentContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0; 
  display: flex;
  justify-content: center;
  width: 100%;
`

const ContentInnerContainer = styled.div`
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 402px;
`

const DescriptionContainer = styled.div`
text-align: center;
color: #333333;
max-width: 381px;
  @media (max-width: ${BREAKPOINT}) {
    max-width: 311px;
  }
`
