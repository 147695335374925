import React from 'react'
import styled from 'styled-components'
import {BREAKPOINT, SIDE_PADDING, Spacer, Text1, Text2, Text3Light, Text5} from './shared'
import {InViewVideoPlayer} from './VideoPlayer'

export default function HowItWorksPage({data}) {
  const how = data.how
  return (
    <HowItWorksContainer id="product">
      <ContentContainer>
        <Spacer height={129} mobileHeight={30}/>
        <TitleContainer>
          <Text1 style={{color: 'white'}}>{how.title}</Text1>
          <Text3Light>{how.subtitle}</Text3Light>
        </TitleContainer>
        <Spacer height={77} mobileHeight={0}/>
        <StepReverseContainer>
          <StepTextContainer>
            <StepNumber>1</StepNumber>
            <Spacer height={16}/>
            <StepTitle>{how.step1Title}</StepTitle>
            <Spacer height={24}/>
            <StepContent>{how.step1Content}</StepContent>
          </StepTextContainer>
          <VideoContainer>
            <InViewVideoPlayer name={'002_ClampedItems'}/>
          </VideoContainer>
        </StepReverseContainer>
        <StepContainer>
          <VideoContainer>
            <InViewVideoPlayer name={'003_FlowWithSound'} loop/>
          </VideoContainer>
          <StepTextContainer>
            <Spacer height={120} mobileHeight={0}/>
            <StepNumber>2</StepNumber>
            <Spacer height={16}/>
            <StepTitle>{how.step2Title}</StepTitle>
            <Spacer height={24}/>
            <StepContent>{how.step2Content}</StepContent>
          </StepTextContainer>
        </StepContainer>
        <StepReverseContainer>
          <StepTextContainer>
            <Spacer height={90} mobileHeight={0}/>
            <StepNumber>3</StepNumber>
            <Spacer height={16}/>
            <StepTitle>{how.step3Title}</StepTitle>
            <Spacer height={34}/>
            <StepContent>{how.step3Content}</StepContent>
          </StepTextContainer>
          <VideoContainer>
            <InViewVideoPlayer name={'004_Exploded'}/>
          </VideoContainer>
        </StepReverseContainer>
        <Step4TextContainer>
          <Spacer height={100}/>
          <StepNumber>4</StepNumber>
          <Spacer height={16}/>
          <StepTitle>{how.step4Title}</StepTitle>
          <Spacer height={34}/>
          <Step4Content>{how.step4Content}</Step4Content>
        </Step4TextContainer>
        <Spacer height={32}/>
        <Video4Container>
          <InViewVideoPlayer name={'005_SatelliteData'} threshold={0}/>
        </Video4Container>
      </ContentContainer>
    </HowItWorksContainer>
  )
}

const HowItWorksContainer = styled.div`
  position: relative;
  background: black;
`

const ContentContainer = styled.div`
  position: relative;
`

const TitleContainer = styled.div`
  padding-left: ${SIDE_PADDING};
`

const StepContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const StepReverseContainer = styled(StepContainer)`
  flex-wrap: wrap-reverse;
`

const StepTextContainer = styled.div`
  width: 50%;
  padding: 0 20px 0 ${SIDE_PADDING};
  
  @media (max-width: ${BREAKPOINT}) {
    width: 100%;
  }
`

const Step4TextContainer = styled.div`
  width: 100%;
  padding: 0 ${SIDE_PADDING};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
  @media (max-width: ${BREAKPOINT}) {
    align-items: flex-start;
    text-align: inherit;
  }
`

const StepNumber = styled(Text2)`
  font-family: 'Gibson-SemiBold', sans-serif;
  color: rgba(255, 255, 255, 0.5);
`

const StepTitle = styled(Text2)`
  color: white;
`

const StepContent = styled(Text5)`
  color: rgba(255, 255, 255, 0.7);
  max-width: 381px;
`

const Step4Content = styled(StepContent)`
  max-width: 555px;
`

const VideoContainer = styled.div`
  width: 50%;
  height: 50vw;
  
  @media (max-width: ${BREAKPOINT}) {
    height: 100vw;
    width: 100%;
  }
`

const Video4Container = styled.div`
  width: 100%;
  height: 100vw;
  max-height: 1100px;
  
`
