import styled from 'styled-components'
import React, {useState, useEffect, useReducer} from 'react'
import {useInView} from 'react-hook-inview'
import Player from '@vimeo/player'


const VIDEO_IDS = {
  '001_FullOGProcess': 389388141,
  '002_ClampedItems': 389341207,
  '003_FlowWithSound': 389387342,
  '004_Exploded': 389387402,
  '005_SatelliteData': 389387447,
  '006_FullOGWithHighlights': 389387488,
  '007_HighlightedSensorsAndFlow': 389387519,
  '008_Wellheads&Separators': 389387546,
  '009_FracPond': 389387571,
  '010_FullOGProcess': 389339836,
  '011_GridofSensors': 389387628,
  '012_FullOGNoMovement': 389387656,
  '013_Transducer': 389387676,
}

export function InViewVideoPlayer({name, loop, threshold, preplay}) {
  loop = loop !== undefined
  threshold = threshold === undefined ? 0.5 : threshold

  const [ref, inView] = useInView({threshold: threshold})

  return (
    <div ref={ref} style={{height: '100%'}}>
      <VideoPlayer name={name} allowPlay={inView} loop={loop}/>
    </div>
  )

}

function reducer(state, action) {
  switch (action.type) {
    case 'createPlayer':
      return {
        ...state,
        canRequestPlayer: true,
      }
    case 'playStart':
      return {
        ...state,
        canRequestPlayer: false
      }
    case 'playSuccess':
      return {
        ...state,
        canRequestPlayer: true,
        isPlaying: true,
      }
    case 'pauseStart':
      return {
        ...state,
        canRequestPlayer: false
      }
    case 'pauseSuccess':
      return {
        ...state,
        canRequestPlayer: true,
        isPlaying: false,
      }
    default:
      return state
  }
}
export function VideoPlayer({name, loop, allowPlay, preplay}) {
  const [player, setPlayer] = useState(null)
  const [state, dispatch] = useReducer(reducer, {
    isPlaying: false,
    canRequestPlayer: false,
  })
  const {isPlaying, canRequestPlayer} = state

  if (canRequestPlayer && allowPlay && !isPlaying) {
    dispatch({type: 'playStart'})
    player.play().then(() => {
      dispatch({type: 'playSuccess'})
    })
  } else if (canRequestPlayer && !allowPlay && isPlaying) {
    dispatch({type: 'pauseStart'})
    player.pause().then(() => {
      dispatch({type: 'pauseSuccess'})
    })
  }

  const videoID = VIDEO_IDS[name]
  const domID = `Video-${videoID}`
  if (videoID === undefined) {
    throw Error(`Unknown Video name "${name}"`)
  }

  useEffect(() => {
    const options = {
      id: videoID,
      controls: false,
      autoplay: false,
      autopause: false,
      byline: false,
      portrait: false,
      loop: loop,
      muted: true,
      dnt: true,
    }
    const player = new Player(domID, options)
    setPlayer(player)
    if (preplay) {
      player.play(() => player.pause())
    }
    dispatch({type: 'createPlayer'})
  }, [domID, videoID, loop, preplay])

  return (
    <VideoContainer id={domID}/>
  )
}

const VideoContainer = styled.div`
  height: 100%;
  
  iframe {
    width: 100%;
    height: 100%;
  }
`