import React from 'react'
import styled from 'styled-components'
import {Spacer, Text1, Text3Dark, Text5, ExternalLink, SIDE_PADDING, BREAKPOINT} from './shared'
import Img from 'gatsby-image'

export default function DemoPage({data}) {
  return (
    <DemoContainer id="demo">
      <Spacer height={100} mobileHeight={81}/>
      <Text>
        <Text1>{data.demo.title}</Text1>
        <Spacer height={2}/>
        <Text3Dark>{data.demo.subtitle}</Text3Dark>
        <Spacer height={17}/>
        <ContentContainer>{data.demo.explanation}</ContentContainer>
      </Text>
      <Spacer height={40}/>
      <ScreenshotContainer>
        <ExternalLink href={data.demo.digitalWellsLink}>
          <Img fluid={data.demo.screenshot.fluid}/>
          <LaunchButton>{data.demo.launch}</LaunchButton>
        </ExternalLink>
      </ScreenshotContainer>
      <Spacer height={40}/>
    </DemoContainer>
  )
}

const DemoContainer = styled.div`
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 ${SIDE_PADDING};
  
  @media (max-width: ${BREAKPOINT}) {
    align-items: flex-start;
    text-align: inherit;
  }
`

const ContentContainer = styled(Text5)`
  color: #333333;
  max-width: 600px;
`

const ScreenshotContainer = styled.div`
  padding: 0 ${SIDE_PADDING};
  position: relative;
  
  @media (max-width: ${BREAKPOINT}) {
    padding: 0;
  }
`

const LaunchButton = styled.div`
  background-color: #017cee;
  font-size: 24px;
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-transform: uppercase;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  
  
  @media (max-width: ${BREAKPOINT}) {
    font-size: 18px;
    border-radius: 0;
  }
`

