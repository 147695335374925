import React from 'react'
import styled from 'styled-components'
import {
  BREAKPOINT,
  SIDE_PADDING,
  Spacer,
  Text2,
  Text5,
  TextStat,
  TextStatLabel,
  StatContainer
} from './shared'
import Img from 'gatsby-image'

export default function AboutPage({data}) {
  return (
    <AboutContainer>
      <StatsContainer>
        <Stat number={data.about.stat1Number} label={data.about.stat1Label}/>
        <Stat number={data.about.stat2Number} label={data.about.stat2Label}/>
        <Stat number={data.about.stat3Number} label={data.about.stat3Label}/>
      </StatsContainer>
      <Spacer height={95} mobileHeight={0}/>
      <TopPaneContainer>
        <LeftPane style={{padding: `0 ${SIDE_PADDING}`}}>
          <Spacer height={141} mobileHeight={30}/>
          <TestimonialContent>{data.about.testimonialContent}</TestimonialContent>
          <Spacer height={30} mobileHeight={21}/>
          <DarkContent>{data.about.testimonialName}</DarkContent>
          <Spacer height={3} mobileHeight={3}/>
          <LightContent>{data.about.testimonialTitle}</LightContent>
          <div><LightContent>{data.about.testimonialCompany}</LightContent></div>
          <Spacer height={0} mobileHeight={60}/>
        </LeftPane>
        <RightTopPane>
          <Img fluid={data.about.testimonialPhoto.fluid}/>
        </RightTopPane>
      </TopPaneContainer>
      <PaneContainer>
        <LeftPane>
          {data.about.showWebinar && <WebinarContainer>
            <Img fluid={data.about.webinarPhoto.fixed} loading="eager" critical/>
            <RegisterButton>
              <a href={data.about.webinarUrl} target="_blank" rel="noopener noreferrer" novalidate/>
            </RegisterButton>
          </WebinarContainer>}
          {!data.about.showWebinar && <Img fluid={data.about.ctaPhoto.fluid}/>}
        </LeftPane>
        <RightPane id="about">
          <TeamContainer>
            <TeamUpperContainer>
              <Spacer height={50} mobileHeight={20}/>
              <TeamTitle>{data.about.aboutTitle}</TeamTitle>
              <Spacer height={20} mobileHeight={20}/>
              <TeamContent>{data.about.aboutContent}</TeamContent>
              <Spacer height={0} mobileHeight={20}/>
            </TeamUpperContainer>
            <TeamLowerContainer>
              <PhotosContainer>
                {data.allDatoCmsTeamMember.edges.map(({node: member}) => (
                  <MemberPhoto>
                    <Img fluid={member.photo.fluid}/>
                  </MemberPhoto>
                ))}
              </PhotosContainer>
              <Spacer height={8} mobileHeight={8}/>
              <TitlesContainer>
                {data.allDatoCmsTeamMember.edges.map(({node: member}) => (
                  <Title>
                    <div>{member.title}</div>
                    <div>{member.name}</div>
                  </Title>
                ))}
              </TitlesContainer>
              <Spacer height={30} mobileHeight={20}/>
              <ManagementTeam>{data.about.managementTeam}</ManagementTeam>
            </TeamLowerContainer>
          </TeamContainer>
          <Spacer height={0} mobileHeight={20}/>
        </RightPane>
      </PaneContainer>
    </AboutContainer>
  )
}

const AboutContainer = styled.div`
  position: relative;
`


const StatsContainer = styled.div`
  padding: 0 ${SIDE_PADDING};
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`

const TeamContainer = styled.div`
    padding: 0 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const TeamContent = styled(Text5)`
    color: #666;
    line-height: 1.2;
    font-weight: 300;
`

const TeamUpperContainer = styled.div`
  padding: 0 20px;
`

const TeamTitle = styled.div`
  font-family: 'LotaGrotesque-Thin';
  font-size: 48px;
  font-weight: 700;
`

const TeamLowerContainer = styled.div`
  background-color: #F4F4F4;
  padding: 20px 0;
`

const PhotosContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`

const TitlesContainer = styled.div`
  display: flex;
  justify-content: space-around;
`

const Title = styled.div`
  width: 20%;
  text-align: center;
  color: #333333;
  line-height: 20px;
  font-size: 14px;
`

const MemberPhoto = styled.div`
  width: 20%;
`

const ManagementTeam = styled.div`
  padding: 0 20px;
  text-align: center;
  font-family: 'LotaGrotesque-Thin';
  font-size: 48px;
  line-height: 48px;
  font-weight: 700;
  color: rgba(0,0,0,0.5);
`

function Stat({number, label}) {
  return (
    <StatContainer style={{width: 'inherit', maxWidth: 345}}>
      <Number>{number}</Number>
      <Label>{label}</Label>
    </StatContainer>
  )
}

const Number = styled(TextStat)`
  color: black;
`

const Label = styled(TextStatLabel)`
  color: #999999;
`

const PaneContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  
  @media (max-width: ${BREAKPOINT}) {
    flex-direction: column;
    text-align: center;
  }
`

const TopPaneContainer = styled(PaneContainer)`
  @media (max-width: ${BREAKPOINT}) {
    flex-direction: column-reverse;
  }
`

const LeftPane = styled.div`
  position: relative;
  width: 55%;
  
  @media (max-width: ${BREAKPOINT}) {
    width: 100%
  }
`

const WebinarContainer = styled.div`
  position: relative;
`

const RegisterButton = styled.div`
  position: absolute;
  height: 6%;
  width: 45%;
  top: 88.5%;
  left: 30%;
  
  a {
    display: block;
    height: 100%;
    width: 100%
  }
`

const RightPane = styled.div`
  width: 45%;
  
  @media (max-width: ${BREAKPOINT}) {
    width: 100%
  }
`

const RightTopPane = styled(RightPane)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const TestimonialContent = styled(Text2)`
  max-width: 600px;
`

const DarkContent = styled(Text5)`
  color: #333333;
`

const LightContent = styled(Text5)`
  color: #999999;
`

